.application__map {
	position: absolute;
	display: block;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100vw;
	height: 100vh;
	z-index: 0;

	&--canvas {
		width: 100%;
		height: 100%;
		outline: none;
		cursor: initial;
	}

	&--canvas-hover {
		cursor: pointer;
	}
}
