@import "../../../stylesheets/bootstrap.scss";
@import "../../../stylesheets/variables.scss";

.zoom-map-bar__wrapper {
	position: absolute;
	bottom: 10px;
	right: 25px;
	z-index: 10;
	
	@include media-breakpoint-down(sm) {
		z-index: 10;
	}

	.zoom-map-bar__container {
		height: 200px;
		width: 35px;

		&--toggle {
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #81cacc;
			border-radius: 3px;
			width: 25px;
			height: 25px;
			margin: 0 auto;
			padding: 3px;
			cursor: pointer;
			opacity: 0.8;

			span {
				width: 100%;
				height: 100%;
				background-size: contain;
				background-repeat: no-repeat;
				display: block;
			}

			.plus {
				background-image: url("./images/plus.svg");
			}

			.minus {
				background-image: url("./images/minus.svg");
				height: 4px;
			}

			&:hover {
				opacity: 1;
			}
		}

		.zoom-map-bar__levels {
			text-align: center;
			&--item {
				display: block;
				width: 55%;
				height: 3px;
				background: $white;
				margin: 8px auto;
				opacity: 0.8;
				cursor: pointer;

				&.active {
					width: 85%;
					opacity: 1;
				}

				&:hover {
					opacity: 1;
				}
			}
		}
	}
}
