@import "../../../stylesheets/variables.scss";
@import "../../../stylesheets//bootstrap.scss";

.simplecontent-page__content {
	&--container {
		text-align: center;
		height: 500px;

		@include media-breakpoint-down(sm) {
			height: calc(100vh - 150px - 72px - 150px);
		}
	}

	&--title {
		color: $white;
		margin-bottom: 25px;
	}

	&--content {
		color: $white;
		// padding: 25px 0;
		text-align: left;
		// overflow: auto;
	}
}
