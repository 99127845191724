@import "../../../stylesheets/variables.scss";
@import "../../../stylesheets/bootstrap.scss";

.search-bar {
	max-width: 80%;
	position: relative;
	flex: 2;
	transition: $transition;

	&.disable {
		opacity: 0;
	}

	@include media-breakpoint-down(sm) {
		width: 100%;
		max-width: 100%;
		flex: initial;
		margin: 25px auto;
	}
}

.search-bar__container {
	.search-bar__search-btn {
		display: block;
		position: absolute;

		width: 32px;
		height: 30px;

		top: 6px;
		left: 6px;

		background: url("./images/button.svg") no-repeat;
	}
}

.search-bar__wrapper {
	width: 100%;

	.search-bar__input {
		&__control {
			background: transparent;
			border-radius: 50px;
			border-color: $white !important;
		}

		&__dropdown-indicator,
		&__indicator-separator {
			display: none;
		}

		&__clear-indicator {
			color: $white;
			cursor: pointer;

			&:hover {
				color: $white;
				transform: scale(1.1);
			}
		}

		&__value-container {
			padding: 4px 45px;

			@include media-breakpoint-down(sm) {
				font-size: 12px;
				padding: 0 45px;
			}
		}

		&__placeholder {
			color: $white;
			opacity: 0.5;
		}

		&__input,
		&__single-value {
			color: $white;
		}
	}
}

.search-bar__input__menu {
	z-index: 9999;
}

.search-bar__searchwords {
	margin-top: 10px;
}

.searchwords__list {
	list-style: none;

	&--item {
		position: relative;
		background: $red;
		color: $white;
		font-size: 14px;

		display: inline-block;
		border-radius: 50px;

		margin-right: 15px;
		margin-bottom: 5px;
		padding: 2px 25px 2px 10px;

		cursor: pointer;

		&:after {
			content: "+";
			display: block;
			position: absolute;
			right: 5px;
			top: -8px;
			font-size: 28px;
			transform: rotate(45deg);
			font-weight: 300;
		}

		&:hover {
			background: $red-dark;
		}
	}

	&--icon {
		display: inline-block;
		height: 20px;
		background-size: contain;
		background-repeat: no-repeat;
		margin-left: 5px;
		vertical-align: top;

		&.substancesHatches {
			width: 15px;
			background-image: url("./images/hatch.svg");
		}

		&.substancesFactories {
			width: 10px;
			background-image: url("./images/pipe.svg");
		}
	}
}
