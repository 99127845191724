@import "../../../stylesheets/bootstrap.scss";
@import "../../../stylesheets/variables.scss";

.map-control__wrapper {
	position: absolute;
	top: calc(150px + 5px);
	right: 25px;
	z-index: 10;

	@include media-breakpoint-down(sm) {
		right: 25px;
		h4 {
			font-size: 14px;
		}
	}

	.map-control__container {
		height: 200px;
		color: $white;
		text-align: right;

		@include media-breakpoint-down(sm) {
			height: auto;
		}
	}

	.map-control__list--item {
		text-align: right;
		width: 100%;
		margin-top: 5px;
	}

	.map-control__list--item-title {
		display: inline-grid;
		width: 70%;
		vertical-align: top;
		font-size: 14px;
		padding-right: 10px;
		line-height: 1rem;

		@include media-breakpoint-down(sm) {
			font-size: 12px;
			line-height: initial;
		}
	}

	.map-control__list--checkbox {
		display: inline-grid;
		grid-template-columns: min-content auto;
		font-size: 2rem;
		color: $red;
		vertical-align: text-bottom;

		@include media-breakpoint-down(sm) {
			font-size: 1.5rem;
		}

		&--input {
			display: grid;
			grid-template-areas: "checkbox";

			> * {
				grid-area: checkbox;
			}

			input {
				opacity: 0;
				width: 1em;
				height: 1em;
				cursor: pointer;

				&:checked + .map-control__list--checkbox--control svg {
					transform: scale(1);
				}
			}
		}

		&--control {
			display: inline-grid;
			width: 1em;
			height: 1em;
			border-radius: 0.25em;
			border: 0.1em solid currentColor;

			svg {
				transition: transform 0.1s ease-in 25ms;
				transform: scale(0);
				transform-origin: bottom left;
			}
		}
	}
}
