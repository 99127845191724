.point {
	visibility: visible;

	&.hidden {
		visibility: hidden;
	}

	&.hover {
		filter: url("#dropshadow");
	}

	&.chimney > #smoke {
		visibility: hidden;
	}
}
