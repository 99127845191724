@import "../../../stylesheets/bootstrap.scss";

.logo__container {
	flex: 1;
	max-width: 205px;
	margin-right: 35px;

	svg {
		height: 100%;
		width: 100%;
	}

	@include media-breakpoint-down(sm) {
		position: relative;
		display: block;
		flex: initial;
		width: 35%;
	}
}
