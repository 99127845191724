@import "../../../stylesheets/variables.scss";
@import "../../../stylesheets//bootstrap.scss";

.popup__box {
	position: fixed;
	display: none;
	justify-content: center;
	align-items: center;

	background: $green-dark;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 999;

	overflow: hidden;

	&.show {
		display: flex;
	}

	&--content {
		position: relative;
		width: 70%;
		max-width: 500px;
		margin: 0 auto;
		height: auto;
		max-height: 70vh;
		border-radius: 4px;
		padding: 20px;
		overflow: auto;
		-webkit-overflow-scrolling: auto; /* Stops scrolling immediately */
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */

		&::-webkit-scrollbar {
			display: none;
		}
	}
}

.date-picker {
	display: flex;
	color: $white;
	background: $popup-green-dark;
	align-items: center;
	justify-content: center;
	text-align: center;

	&__wrapper {
		padding: 25px 50px;
	}

	.date-picker__params {
		display: flex;
		justify-content: center;
		padding: 15px 0;

		@include media-breakpoint-down(sm) {
			flex-direction: column;
		}

		&--select {
			width: 100%;

			.months__control,
			.years__control {
				background-color: $popup-green-dark;
				color: $white;
				border-radius: 20px;
				box-shadow: none;
				border-color: $white !important;
			}

			.months__single-value,
			.years__single-value {
				color: $white;
			}

			.months__indicator-separator,
			.years__indicator-separator {
				display: none;
			}

			.months__dropdown-indicator,
			.years__dropdown-indicator {
				color: $white;
			}

			&:first-child {
				margin-right: 10%;
				flex: 2;
			}

			&:nth-child(2) {
				flex: 1;
			}

			@include media-breakpoint-down(sm) {
				flex: 1 !important;
				margin-bottom: 5px;
			}
		}
	}

	.date-picker__calendar {
		.react-calendar__month-view__weekdays {
			&__weekday {
				abbr {
					text-decoration: none;
					font-family: "Gotham Pro";
					font-weight: 200;
				}

				&:nth-child(6),
				&:nth-child(7) {
					abbr {
						color: $red;
					}
				}
			}
		}

		.react-calendar__month-view__days {
			&__day {
				border: 2px solid transparent;
				color: $white;
				margin-bottom: 5px;
				padding: 5px 0;

				&--weekend {
					background: #675051 !important;
					&:nth-child(2n + 1) {
						background: #7c5a5b !important;
					}
				}

				abbr {
					font-family: "Gotham Pro";
					font-weight: 600;
				}
				&:nth-child(2n + 1) {
					background: #6a7576;
				}
				&:nth-child(2n) {
					background: #546263;
				}
			}
		}

		.react-calendar__tile {
			outline: none;

			&--active {
				border: 2px solid $turquoise-dark;
			}
		}
	}

	.date-picker__actions {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 15px 0 0;

		&--btn {
			border: none;
			background: $white;
			border-radius: 50px;
			width: 30%;
			min-width: 100px;

			&:first-child {
				margin-right: 25px;
			}

			&:hover {
				cursor: pointer;
				background: #dadada;
			}
		}
	}
}
