@import "../../stylesheets/variables.scss";
@import "../../stylesheets//bootstrap.scss";

.app-loader {
	display: none;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 999;

	width: 100vw;
	height: 100vh;

	&.show {
		display: block;
	}

	.app-loader__container {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background: $green-dark;

		.pipes__container {
			display: flex;

			@include media-breakpoint-up(lg) {
				transform: scale(0.5);
			}

			.pipe__wrapper {
				position: relative;
				margin-left: 50px;

				@include media-breakpoint-down(sm) {
					margin-left: 25px;
				}

				.smoke {
					position: absolute;
					display: block;
					width: 50px;
					height: 50px;
					border-radius: 50px;
					background: #fff;
					opacity: 0;
					z-index: 0;
					top: 0;
					left: 0;
					right: 0;
					margin: 0 auto;

					animation-name: smoke-moving;
					animation-timing-function: cubic-bezier(0, -0.04, 0, 1.55);
					animation-duration: 2s;
					animation-delay: 2.5s;
					animation-iteration-count: infinite;

					@include media-breakpoint-down(sm) {
						width: 25px;
						height: 25px;
						border-radius: 25px;
					}

				}

				&:nth-child(2) {
					.smoke {
						animation-delay: 5s;
					}
				}

				&:nth-child(3) {
					.smoke {
						animation-delay: 7.5s;
					}
				}
			}
			.pipe {
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 100px;
				height: 0px;
				border-left: 25px solid transparent;
				border-right: 25px solid transparent;
				z-index: 10;

				animation-name: pipe-bounce;
				animation-timing-function: ease;
				animation-duration: 2s;
				// animation-delay: 1s;
				animation-iteration-count: infinite;

				&.blue {
					border-bottom: 200px solid #2bb0b3;
					// animation-delay: 0s;
					@include media-breakpoint-down(sm) {
						border-bottom: 70px solid #2bb0b3;
					}
				}
				&.red {
					border-bottom: 200px solid #d07372;
					// animation-delay: 2s;
					@include media-breakpoint-down(sm) {
						border-bottom: 70px solid #d07372;
					}
				}
				&.yellow {
					border-bottom: 200px solid #e6a23b;
					// animation-delay: 4s;
					@include media-breakpoint-down(sm) {
						border-bottom: 70px solid #e6a23b;
					}
				}

				@include media-breakpoint-down(sm) {
					width: 50px;
					border-left: 15px solid transparent;
					border-right: 15px solid transparent;
				}

				span.pipe__decorate {
					display: block;
					height: 0px;

					border-left: 2px solid transparent;
					border-right: 2px solid transparent;

					&:nth-child(1) {
						width: 45px;
						border-bottom: 20px solid #fff;
						margin-top: 40px;

						@include media-breakpoint-down(sm) {
							width: 15px;
							border-bottom: 10px solid #fff;
							margin-top: 5px;
						}
					}
					&:nth-child(2) {
						width: 60px;
						border-bottom: 20px solid #fff;
						margin-top: 35px;
						@include media-breakpoint-down(sm) {
							width: 25px;
							border-bottom: 10px solid #fff;
							margin-top: 15px;
						}
					}
					&:nth-child(3) {
						width: 75px;
						border-bottom: 20px solid #fff;
						margin-top: 35px;
						@include media-breakpoint-down(sm) {
							width: 34px;
							border-bottom: 10px solid #fff;
							margin-top: 15px;
						}
					}
				}
			}
		}
	}
}

@keyframes pipe-bounce {
	0% {
		transform: scale(1, 1) translateY(0);
	}
	10% {
		transform: scale(1.1, 0.9) translateY(0);
	}
	30% {
		transform: scale(0.9, 1.1) translateY(-20px);
	}
	50% {
		transform: scale(1, 1) translateY(0);
	}
	100% {
		transform: scale(1, 1) translateY(0);
	}
}
@keyframes smoke-moving {
	0% {
		top: 0;
		transform: scale(1);
	}
	10% {
		transform: scale(1);
		opacity: 0.4;
	}
	50% {
		transform: scale(1);
		opacity: 0.3;
	}
	100% {
		opacity: 0;
		transform: scale(1.3);
		top: -100px;
	}
}
