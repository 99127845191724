@import "../../stylesheets/variables.scss";
@import "../../stylesheets//bootstrap.scss";

.app-mobile-promt {
	display: none;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 999;

	width: 100vw;
	height: 100vh;

	&.show {
		display: block;
	}

	.app-mobile-promt__wrapper {
		width: 100%;
		height: 100%;
		display: flex;
		background-color: $green-dark;
	}

	.app-mobile-promt__container {
		display: flex;
		width: 100%;
		text-align: center;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		&--icon {
			width: 80px;
			height: 70px;
			margin-left: 40px;
			background: url("./images/mobile.png") no-repeat;
			background-size: contain;
		}

		&--notice {
			color: #ffffff;
			margin-top: 15px;
			font-weight: 600;
			font-size: 12px;
			padding: 0 15px;
		}
	}
}
