@import "../../stylesheets/variables.scss";
@import "../../stylesheets/bootstrap.scss";

header.header {
	background: $header-gradient;
	position: relative;
	z-index: 99;
	
	&.header-red {
		background: $red;
	}
	.header__container {
		width: 100%;
		height: 150px;

		display: flex;
		align-items: flex-start;
		justify-content: center;

		padding-top: 35px;

		@include media-breakpoint-down(sm) {
			display: block;
			padding-top: 0;
			padding: 30px 25px;
		}
	}
}
