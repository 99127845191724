@import "./variables.scss";

body {
	height: 100%;
	margin: 0;
	padding: 0;

	font-family: "Gotham Pro";
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

img {
	max-width: 100%;
	height: auto;
}

ul,
ol {
	margin: 0;
	padding: 0;

	list-style: none;
}

a {
	transition: opacity $transition;
}

a:hover {
	text-decoration: none;
	color: inherit;

	opacity: 0.8;
}

p {
	margin: 0;
	padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}