@import "../../stylesheets/variables.scss";
@import "../../stylesheets/bootstrap.scss";

.weather-component {
	position: absolute;
	bottom: 5%;
	left: 5%;

	width: 20vw;

	z-index: 10;

	@include media-breakpoint-down(sm) {
		width: 100%;
		bottom: 15px;
		left: 0;
	}

	.weather__container {
		display: flex;
		justify-content: center;
		align-items: flex-end;

		@include media-breakpoint-down(sm) {
			padding: 0 25px;
			justify-content: flex-start;
			margin-bottom: -20px;
		}

		&--union {
			display: flex;
			flex: 1;
			padding-bottom: 15px;
			.union-icon {
				width: 31px;
				height: 31px;
				background: url("./images/union.png") no-repeat;
				background-size: contain;
				cursor: pointer;

				&:hover {
					transform: scale(1.1);
				}
			}

			@include media-breakpoint-down(sm) {
				flex: initial;
				padding-bottom: 7px;

				.union-icon {
					width: 21px;
					height: 21px;
				}
			}
		}

		&--wind {
			flex: 3;
			padding-left: 15px;

			@include media-breakpoint-down(sm) {
				flex: initial;
			}

			.wind-arrow {
				width: 60px;
				height: 70px;
				background: url("./images/arrow.svg") no-repeat;
				background-size: contain;

				@include media-breakpoint-down(sm) {
					width: 50px;
					height: 55px;
				}
			}

			.weather__content--wind-wrapper {
				display: block;
				margin-top: 15px;

				.weather__content--wind-wrapper {
					color: $white;
				}
			}
		}
	}

	.weather__content {
		&--wind-wrapper {
			display: block;
		}

		&--wind-title {
			color: $white;
			font-size: 18px;

			@include media-breakpoint-down(sm) {
				font-size: 12px;
			}
		}

		&--wind-value {
			display: block;
			color: $white;
			font-weight: 900;
			font-size: 32px;
			margin-top: -10px;

			@include media-breakpoint-down(sm) {
				margin-top: -5px;
				font-size: 16px;
			}
		}
	}

	.weather__date {
		@include media-breakpoint-down(sm) {
			display: flex;
			justify-content: flex-end;
			padding-right: 25px;
		}

		&--time {
			margin-right: 40px;
			@include media-breakpoint-down(sm) {
				margin-right: 10px;
			}
		}

		&--time,
		&--date {
			color: $white;
			font-size: 18px;
			display: inline-block;
			@include media-breakpoint-down(sm) {
				font-size: 14px;
			}
		}

		&--time-minutes {
			color: $turquoise-dark;
		}
	}
}
