@import "../../stylesheets/variables.scss";

.index-page__container {
    width: 100%;
    height: 100vh;
    
    position: relative;
    
    // overflow: hidden;
    background: $green-dark;
}