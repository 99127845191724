svg.svg-overlay {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	background: transparent;
	position: absolute;
}

svg {
	pointer-events: none;
	user-select: none;
}
