@import "../../../stylesheets/variables.scss";
@import "../../../stylesheets/bootstrap.scss";

.menu-burger__container {
	flex: 1;
	display: flex;
	position: relative;

	justify-content: flex-end;

	padding-top: 12.75px;

	@include media-breakpoint-down(sm) {
		position: absolute;
		top: 30px;
		right: 25px;
		padding-top: 0;
	}
}

.menu-burger__icon {
	position: relative;
	display: block;
	width: 30px;
	height: 22px;

	cursor: pointer;
	transition: 0.5s;

	&--line {
		position: relative;
		display: block;
		margin-bottom: 6px;
		width: 100%;
		height: 3px;
		background: $white;
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
		transition: 0.5s;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&.active {
		.menu-burger__icon--line {
			margin-bottom: 0;
			position: absolute;
			top: 0;
		}
		.menu-burger__icon--line:first-child {
			transform: rotate(45deg);
		}
		.menu-burger__icon--line:last-child {
			transform: rotate(135deg);
		}
		.menu-burger__icon--line:nth-child(2) {
			background: rgba(0, 0, 0, 0);
			box-shadow: none;
		}
	}
}
