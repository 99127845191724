@font-face {
	font-family: "Gotham Pro";
	src: url("./fonts/GothamPro/GothamPro-Bold.eot");
	src: local("Gotham Pro Bold"), local("GothamPro-Bold"), url("./fonts/GothamPro/GothamPro-Bold.eot?#iefix") format("embedded-opentype"), url("./fonts/GothamPro/GothamPro-Bold.woff") format("woff"), url("./fonts/GothamPro/GothamPro-Bold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "Gotham Pro";
	src: url("./fonts/GothamPro/GothamPro-Light.eot");
	src: local("Gotham Pro Light"), local("GothamPro-Light"), url("./fonts/GothamPro/GothamPro-Light.eot?#iefix") format("embedded-opentype"), url("./fonts/GothamPro/GothamPro-Light.woff") format("woff"), url("./fonts/GothamPro/GothamPro-Light.ttf") format("truetype");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "Gotham Pro";
	src: url("./fonts/GothamPro/GothamPro-Black.eot");
	src: local("Gotham Pro Black"), local("GothamPro-Black"), url("./fonts/GothamPro/GothamPro-Black.eot?#iefix") format("embedded-opentype"), url("./fonts/GothamPro/GothamPro-Black.woff") format("woff"), url("./fonts/GothamPro/GothamPro-Black.ttf") format("truetype");
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: "Gotham Pro";
	src: url("./fonts/GothamPro/GothamPro.eot");
	src: local("Gotham Pro"), local("GothamPro"), url("./fonts/GothamPro/GothamPro.eot?#iefix") format("embedded-opentype"), url("./fonts/GothamPro/GothamPro.woff") format("woff"), url("./fonts/GothamPro/GothamPro.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}
