// Colors
$turquoise:#7cf8f8;
$turquoise-dark:#81CACC;

$red:#D17373;
$red-dark:#885050;

$white: #FFFFFF;
$green-dark:#0F2B2E;
$popup-green-dark: #0C2527;


// Font weight

$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$heavy: 900;

// Breakpoints

$mb: 320px;
$tb: 768px;
$dt: 992px;

// Other

$transition: 0.5s ease;

$header-gradient: linear-gradient(180deg, #102c2e 70%, rgba(30, 51, 53, 0.1) 95%, rgba(30, 51, 53, 0) 100%);