@import "../../stylesheets/variables.scss";
@import "../../stylesheets/bootstrap.scss";


.aboutus-page__section {
    background: $red;
    display: block;
    position: relative;
    z-index: 9999;
    
    width: 100%;
    height: calc(100vh - 150px);

    .aboutus-page__container {
        height: calc(100vh - 150px - 50px);
        // height: calc(100% - 25px - 27px);
        overflow-y: scroll;
    }
}

.aboutus-page__content {
    height: 100%;
}

.aboutus-page__nav {
    position: absolute;
    bottom: 25px;
    left: 0;
    right: 0;

    .aboutus-page__nav--list {
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }

        li {
            font-size: 18px;
            color: $white;
            margin-right: 70px;
            
            @include media-breakpoint-down(sm) {
                margin-right: 0;
                font-size: 16px;
            }
    
            &:last-child {
                margin-right: 0;
            }

            &:hover {
                opacity: .4;
                cursor: pointer;
            }

            &.active {
                opacity: .4;
            }
        }
    }
}