@import "../../stylesheets/variables.scss";
@import "../../stylesheets/bootstrap.scss";

.factory-info-sidebar {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	z-index: 99999;
	display: none;

	@include media-breakpoint-down(sm) {
		top: 175px;
	}

	&--container {
		position: relative;
		width: 30vw;
		height: 100%;
		background: $green-dark;
		// background: #515151;
		// display: flex;
		// justify-content: center;

		@media only screen and (max-width: 576px) and (max-device-height: 640px) {
			height: 65vh !important;
		}

		@include media-breakpoint-down(sm) {
			width: 100vw;
			height: 55vh; // was 75vh
		}

		
	}

	&.show-from-left {
		display: block;

		@include media-breakpoint-down(sm) {
			display: flex;
			align-items: flex-end;
		}
	}
}

.factory-info__content {
	display: flex;
	max-height: 100%;

	flex-direction: column;
	flex-grow: 0;

	&--header {
		background: $red;
		text-align: center;
		padding: 25px 40px;
		flex: 1;

		@include media-breakpoint-down(sm) {
			padding: 30px 15px 15px 15px;
		}
	}

	&--header-img {
		width: 80%;
		margin: 15px auto;

		@include media-breakpoint-down(sm) {
			width: 35%;
			margin: initial;
			display: inline-block;
			vertical-align: top;
		}
	}

	&--header-wrapper {
		@include media-breakpoint-down(sm) {
			width: 65%;
			display: inline-block;
			text-align: left;
			padding-left: 15px;
		}
	}

	&--additional {
		flex: 1;
		background: #803d2f;
		text-align: center;
		padding: 5px 0;
		p {
			font-size: 14px;
			color: $white;
		}
	}

	&--substances-header {
		flex: 1;
	}

	&--substances {
		flex: 2;
		overflow: auto;
	}

	&--title {
		font-size: 1em;
		span.small {
			display: block;
			font-size: 14px;
		}

		@include media-breakpoint-down(sm) {
			font-size: 14px;
			word-break: break-word;
			span.small {
				font-size: 12px;
			}
		}
	}

	&--title,
	&--address {
		color: $white;
	}
	&--address {
		font-weight: 300;

		@include media-breakpoint-down(sm) {
			font-size: 8px;
		}
	}
}

.factory-info__content--substances-header {
	background: $red-dark;
	display: flex;
	align-items: center;
	justify-content: space-around;
	width: 100%;
	min-height: fit-content;
	span {
		color: $white;
		display: block;
	}

	.title-wrapper {
		padding: 10px 0;
		text-align: center;
		display: block;
	}

	.small-subtitle {
		font-size: 12px;
	}
}

.factory-info__content--substances-wrapper {
	height: 100%;
	width: 100%;
	display: block;
	position: relative;

	.factory-info__substances--list {
		overflow-x: hidden;
		overflow-y: scroll;

		-webkit-overflow-scrolling: auto; /* Stops scrolling immediately */
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */

		&::-webkit-scrollbar {
			display: none;
		}
	}

	.factory-info__substances--item {
		text-align: center;
		color: $white;
		padding: 5px 2px 10px 2px;

		&:nth-child(2n) {
			background: #5d5d5d;
		}
		&:nth-child(2n + 1) {
			background: #515151;
		}

		h3 {
			padding-bottom: 5px;
			font-size: 0.9em;
		}
	}

	.factory-info__substances--item-values {
		display: flex;
		align-items: center;
		justify-content: space-around;
		width: 100%;

		span {
			display: block;
		}
	}
}

.factory-info-sidebar--control {
	position: absolute;
	top: 25px;
	right: 10px;

	@include media-breakpoint-down(sm) {
		top: 5px;
		right: 5px;
	}

	.control-close {
		display: block;
		position: relative;
		width: 25px;
		height: 25px;
		cursor: pointer;

		span {
			position: absolute;
			display: block;
			width: 100%;
			height: 3px;
			top: 10px;
			background: #ffffff;
			opacity: 0.8;
			transition: 0.5s;

			&:first-child {
				transform: rotate(45deg);
			}
			&:nth-child(2) {
				transform: rotate(135deg);
			}
		}

		&:hover {
			span {
				opacity: 1;
			}
		}
	}
}
